import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import pageBase from '../components/layouts/PageBase/reducer';
import login from '../pages/Login/reducer';
import forgotPassword from '../pages/ForgotPassword/reducer';
import resetPassword from '../pages/ResetPassword/reducer';
import home from '../pages/Home/reducer';
import stock from '../pages/Stock/reducer';
import payment from '../pages/TipePembayaran/reducer';
import transaksi from '../pages/Transaksi/reducer';
import prices from '../pages/SettingHarga/reducer';
import merchantRegistrations from '../pages/RegistrasiMerchant/reducer';
import faq from '../pages/Faq/reducer';
import manualLoggingActivation from '../pages/AktivasiPencatatanManual/reducer';
// import verificationRegistrationMerchant from '../pages/VerificationRegistrationMerchant/reducer';
import user from '../pages/User/reducer';
import role from '../pages/Role/reducer';
import permission from '../pages/Permission/reducer';
import merchantVerification from '../pages/VerificationRegistrationMerchant/reducer';
import merchantSetting from '../pages/SettingMerchant/reducer';
import auditLog from '../pages/AuditLog/reducer';
import myptmActivation from '../pages/AktivasiMyPertamina/reducer';
import termCondition from '../pages/TermCondition/reducer';
import notification from '../pages/Notification/reducer';
import logsSyncMerchant from '../pages/SinkronisasiMerchant/reducer';
import updateStockMerchant from '../pages/UpdateStockMerchant/reducer';
import scanCodeMfa from '../pages/MfaScanCode/reducer';
import mfa from '../pages/Mfa/reducer';
import merchantNotOnboard from '../pages/MerchantNotOnboarding/reducer';
import profile from '../pages/Profile/reducer';
import setupAuthenticator from '../pages/SetupAuthenticator/reducer';

const rootReducer = combineReducers({
  form: formReducer,
  login,
  forgotPassword,
  resetPassword,
  home,
  stock,
  payment,
  transaksi,
  pageBase,
  prices,
  merchantRegistrations,
  routing: routerReducer,
  manualLoggingActivation,
  faq,
  user,
  role,
  permission,
  merchantVerification,
  merchantSetting,
  auditLog,
  myptmActivation,
  termCondition,
  notification,
  logsSyncMerchant,
  updateStockMerchant,
  scanCodeMfa,
  mfa,
  merchantNotOnboard,
  profile,
  setupAuthenticator,
});

export default rootReducer;
